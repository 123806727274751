import React from 'react'
import { connect } from 'react-redux'
import { Typography, Spin } from 'antd'

const Loading = ({ loading }) => loading ? (
  <div className='loading-screen' style={{position:'absolute',top:'0',right:0,width:'100%'}}>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'calc(100vh - 70px)'}}>
      <div style={{width:'100%'}}>
        <div style={{textAlign:'center',marginBottom:'20px',padding:'10px 50px',margin:'10px 0'}}>
          <Spin size="large" />
        </div>
        <Typography.Title className='text-center' style={{color:'#0074bc'}} level={4}>Loading..</Typography.Title>
      </div>
    </div>
  </div>
):null

export default connect(state => ({ loading:state.app.loading }))(Loading)
