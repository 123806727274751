import React from 'react'
import { Row, Col, Typography, Statistic, Button, Input, Table, Tag, Select, Divider } from 'antd'

const TransferLicenses = ({ code, info, clearView }) => {

  return (
    <>
      Coming Soon!

      <Divider/>
      <Row style={{marginTop:'20px'}}>
        <Col style={{textAlign:'left'}} flex='5'>
          <Button onClick={clearView} type='default'>Go Back</Button>
        </Col>
      </Row>
    </>
  )
}

export default TransferLicenses
