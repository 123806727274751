import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Typography, Statistic, Button, Input, Table, Tag } from 'antd'
import { get } from 'State/Actions'
import Form from './Form'
import Display from './Display'

class LicenseCodes extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data:null,
      error:null
    }
    if (props.match.params.code) this.loadLicenseData(props.match.params.code)
  }

  clearError = () => this.setState({ error:null })

  loadLicenseData = (code,fromSubmit=false) => {
    this.props.dispatch(get('/utils/records/licenses/get', { code }, resp => {
      console.log(resp)
      if (resp.error) {
        this.setState({ error:resp.error })
      } else {
        this.props.history.push(`/licensing/license-codes/${code}`)
        this.setState({ data:resp })
      }
    }, fromSubmit ? false:true))
  }

  reload = () => this.loadLicenseData(this.props.match.params.code)

  componentDidUpdate(prevProps) {
    if (!this.props.match.params.code && prevProps.match.params.code) this.setState({ data:null, error:null })
  }

  render() {
    let { data, error } = this.state

    return data ?
      <Display code={this.props.match.params.code} reload={this.reload} info={data}/>
      :
      <Form onSubmit={code => this.loadLicenseData(code,true)} clearError={_=>this.setState({ error:null })} error={error}/>
  }
}

export default connect(state => ({

}))(LicenseCodes)
