import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from 'State/Config'
import { init } from 'State/Actions'
import App from './App'
import 'antd/dist/antd.css'

if (process.env.NODE_ENV !== 'production') {
  window.DEBUG_MODE = true
}

const store = configureStore()
store.dispatch(init())

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
)
