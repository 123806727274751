import React from 'react'
import { Typography, Col } from 'antd'

export default ({ title, text, img }) => (
  <Col className='text-center' flex='325px'>
    <div style={{ padding:'20px 30px', background:'#fff', borderRadius:'5px' }}>
      <Typography.Title style={{color:'#0074bc'}} level={4}>{ title }</Typography.Title>
      <Typography.Paragraph style={{ marginTop:'15px', minHeight:'90px' }}>
        { text }
      </Typography.Paragraph>
      <img src={img} style={{marginTop:'10px'}} height={'150px'} width={'80%'}/>
    </div>
  </Col>
)
