import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, Typography, Statistic, Button, Input, Table, Tag, Select, Divider } from 'antd'
import Info from './Info'
import Manage from './Manage'

class Display extends React.Component {

  state = {
    manage:null,
  }

  reload = () => {
    this.clearView()
    this.props.reload()
  }

  clearView = () => this.setState({ manage:null })
  setApplyView = () => this.setState({ manage:'apply' })
  setTransferView = () => this.setState({ manage:'transfer' })

  render() {
    let { code, info } = this.props
    let { manage } = this.state

    return (
      <Row justify='center' align='top' style={{marginTop:'50px'}}>
        <Col flex={1}>
          <div>
            <Typography.Title className='text-center' style={{color:'#0074bc'}} level={3}>
              Licence Code:
            </Typography.Title>
          </div>
          <div>
            <Typography.Paragraph className='text-center' style={{fontSize:'16px',fontWeight:600,color:'#dc1e26'}}>
              { code }
            </Typography.Paragraph>
          </div>
          <div style={{marginTop:'30px',display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'50px'}} className='text-center'>
            <div style={{background:'#fff', width:'80%',padding:'40px 30px',borderRadius:'5px'}} className='text-center'>
              { manage ?
                <Manage reload={this.reload} view={manage} code={code} info={info} clearView={this.clearView}/>
                :
                <Info info={info} showApplyView={this.setApplyView} showTransferView={this.setTransferView}/>
              }
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}

export default connect(state => ({}))(Display)
