import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, Typography, Statistic, Button, Input, Table, Tag } from 'antd'
import { get } from 'State/Actions'
import Form from './Form'
import Display from './Display'

class CueServers extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      data:null,
      error:null
    }
    if (props.match.params.serial) this.loadSerialData(props.match.params.serial)
  }

  clearError = () => this.setState({ error:null })

  loadSerialData = (serial,fromSubmit=false) => {
    this.props.dispatch(get('/utils/records/cueserver/get', { serial }, resp => {
      console.log(resp)
      if (resp.error) {
        this.setState({ error:resp.error })
      } else {
        this.props.history.push(`/licensing/cueservers/${serial}`)
        this.setState({ data:resp })
      }
    }, fromSubmit ? false:true))
  }

  reload = () => this.loadSerialData(this.props.match.params.code)

  componentDidUpdate(prevProps) {
    if (!this.props.match.params.serial && prevProps.match.params.serial) this.setState({ data:null, error:null })
  }

  render() {
    let { data, error } = this.state

    return data ?
      <Display serial={this.props.match.params.serial} reload={this.reload} info={data}/>
      :
      <Form onSubmit={code => this.loadSerialData(code,true)} clearError={_=>this.setState({ error:null })} error={error}/>
  }
}

export default connect(state => ({

}))(CueServers)
