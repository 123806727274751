import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Typography, Row, Col, Button } from 'antd'
import OptimizedPhrase from 'Components/OptimizedPhrase'
import PageTitle from 'Components/PageTitle'

class Home extends React.Component {

  componentDidMount() {
    
  }

  render() {

    return (
      <>
        <PageTitle>Home</PageTitle>
        <OptimizedPhrase center button/>
      </>
    )
  }
}

export default connect(state => ({}))(Home)
