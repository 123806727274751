import React from 'react'
import { Row, Col, Typography } from 'antd'

export default ({ children }) => (
  <Row gutter={[0,40]} style={{marginTop:'20px'}}>
    <Col span={24}>
      <Typography.Title className='text-center' style={{color:'rgba(0, 0, 0, 0.65)'}} level={1}>{children}</Typography.Title>
    </Col>
  </Row>
)
