import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, Typography, Statistic, Button, Table, Tag, Divider } from 'antd'

export default ({ info, showTransferView, showApplyView }) => {

  let columns = [
    { title: 'Date', dataIndex: 'date', key: 'date', },
    { title: 'Made By', dataIndex: 'user', key: 'user', },
    { title: 'Universes', dataIndex: 'universes', key: 'universes', },
    {
      title: 'Serial No.',
      dataIndex: 'cueserver',
      key: 'cueserver',
      render: tag => (
        <Link to={`/licensing/cueservers/${tag}`}>
          <Tag style={{cursor:'pointer'}} color='geekblue' key={tag}>
            {tag}
          </Tag>
        </Link>
      )
    },
  ]

  let transactions = info.transactions.map((x,i)=>({ ...x, key:i }))

  return (
    <>
      <Row justify='center'>
        <Col flex='1'>
          <Statistic title="Total Purchased" value={info.totalPurchased} />
        </Col>
        <Col flex='1'>
          <Statistic title="Current Ballance" value={info.remainingUniverses} />
        </Col>
        <Col flex='1'>
          <Statistic title="Total Transactions" value={info.transactions.length} />
        </Col>
      </Row>
      { info.transactions.length > 0 &&
        <>
          <Typography.Title className='text-center' style={{marginTop:'50px',fontWeight:400}} level={4}>
            Transaction History:
          </Typography.Title>
          <Row style={{marginTop:'20px'}}>
            <Col flex='5'>
              <Table pagination={2} onChange={e=>console.log({...e})} size="small" dataSource={transactions} columns={columns}/>
            </Col>
          </Row>
        </>
      }
      <Divider/>
      <Row style={{marginTop:'20px'}}>
        <Col style={{textAlign:'right'}} flex='5'>
          <Button onClick={showTransferView} type='default' style={{marginRight:'10px'}}>Transfer Balance</Button>
          <Button onClick={showApplyView} type='default'>Apply Universes</Button>
        </Col>
      </Row>
    </>
  )
}
