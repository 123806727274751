import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

const AdminRoute = ({ authenticated, internal, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        (authenticated && internal) ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default connect(state=> ({
  authenticated:state.app.authenticated,
  internal:state.app.internal
}))(AdminRoute)
