import React from 'react'
import Apply from './Apply'
import Transfer from './Transfer'

export default props => {
  return props.view === 'apply' ?
    <Apply { ...props }/>
    :
    <Transfer { ...props}/>
}
