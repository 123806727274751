import React from 'react'
import { Row, Col, Typography, Button, Input } from 'antd'

class Form extends React.Component {

  state = {
    code:null,
    loading:false,
    valid:false
  }

  formatCode = code => {
    code = code.replace(new RegExp(/[-||\s]/g),'').toUpperCase().split('')
    let out = ''
    for (let i=1; i<=code.length; i++) out+= `${code[i-1]}${i!==0 && i % 4 === 0 && i!==code.length ? '-':''}`
    return out
  }

  isValidCode = code => code && /[0-9|A-Z]{4}-[0-9|A-Z]{4}-[0-9|A-Z]{4}-[0-9|A-Z]{4}/.test(code)

  onCodeChange = e => {
    let val = e.target.value
    if (val.length > 24) return
    if (val!==null&&val!==undefined) {
      val = val.replace(new RegExp(/[^a-z|^A-Z|^0-9|^-]/g),'')
      let code = this.formatCode(val)
      if (this.props.error) this.props.clearError()
      this.setState({ code, valid:this.isValidCode(code) })
    }
  }

  onLicenseSubmit = () => {
    if (this.isValidCode(this.state.code)) {
      this.setState({ loading:true })
      this.props.onSubmit(this.state.code)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      this.setState({ code:null, loading:false })
    }
  }

  render() {
    let { code, loading, valid } = this.state
    let error = this.props.error||this.state.error||null

    return (
      <Row justify='center' align='top' style={{marginTop:'100px'}}>
        <Col flex={1}>
          <div>
            <Typography.Title className='text-center' style={{color:'#0074bc'}} level={3}>
              Provide a License Code
            </Typography.Title>
          </div>
          <div>
            <Typography.Paragraph className='text-center'>
              Please enter your Universe License Code below:
            </Typography.Paragraph>
          </div>
          <div style={{marginTop:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} className='text-center'>
            <Row justify='center' gutter={10}>
              <Col flex='4'>
                <Input onChange={this.onCodeChange} value={code} style={{width:'275px'}} size='medium' placeholder="XXXX-XXXX-XXXX-XXXX-XXXX" />
              </Col>
              <Col flex='auto'>
                <Button size='medium' onClick={this.onLicenseSubmit} loading={loading} disabled={valid ? false:true} type='primary'>Find</Button>
              </Col>
            </Row>
          </div>
          <div style={{ display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>
            <Typography.Paragraph type='danger' className={`text-center form-error${error ? ' visible':''}`}>{ error }</Typography.Paragraph>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Form
