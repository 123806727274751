import React from 'react'
import { connect } from 'react-redux'
import { Typography } from 'antd'
import Loading from 'Components/Loading'
import denied from 'assets/access_denied.svg'
import error from 'assets/maintenance.svg'
import unavailable from 'assets/sold_out.svg'

const presets = {
  error:{
    img:error,
    title:'Offline',
    message:<>We're working on our servers right now.<br/>Please check back later.</>
  },
  unavailable:{
    img:unavailable,
    title:'Uh Oh!',
    message:<>It looks like there aren't any CueServers available right now.<br/>Please check back later.</>
  },
  denied:{
    img:denied,
    title:'Access Denied',
    message:<>You dont have access to this resource.</>
  }
}

const Status = ({ status, children }) => {
  if (!status) return children
  let data = {}
  if (typeof status === 'object') {
    data = presets[status.type]||presets.unavailable
    data.title = status.title||data.title
    data.message = status.message||data.message
    data.img = status.img||data.img
  } else {
    data = presets[status]||presets.unavailable
  }
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'calc(100vh - 70px)'}}>
      <div style={{display:'inline-block',width:'auto',textAlign:'center',background:'rgba(0, 0, 0, 0.05)',borderRadius:'4px',marginBottom:'20px',padding:'40px 100px',margin:'20px auto'}}>
        <img src={data.img} style={{display:'block',marginBottom:'50px',marginLeft:'calc(50% - 75px)',maxWidth:'150px'}}/>
        <Typography.Title className='text-center' level={3}>{ data.title }</Typography.Title>
        <Typography.Paragraph className='text-center' strong style={{marginTop:'15px'}}>
          <span dangerouslySetInnerHTML={{ __html:data.message }}/>
        </Typography.Paragraph>
      </div>
    </div>
  )
}

export default connect(state => ({
  status:state.app.status
}))(Status)
