import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import AuthForm from './AuthForm'
import EmailForm from './EmailForm'
import { Row, Col, Typography } from 'antd'
import { Session } from 'Utils'
import { post, getUserReservations, authenticateUser } from 'State/Actions'
const { Title, Paragraph } = Typography

class Authenticate extends React.Component {

  redirectFrom = this.props.location.state ? this.props.location.state.from.pathname:null

  state = Session('auth/status')||{
    email:null,
    sent:false,
    invalidAuthCode:false
  }

  onReset = () => {
    Session('auth/status',{ sent:false, email:null })
    this.setState({ sent:false, email:null })
  }

  onSubmitEmail = email => {
    this.props.dispatch(post('/security/auth/send', { email }))
    Session('auth/status', { email, sent:true })
    this.setState({ email, sent:true })
  }

  onAuthenticated = (user,authentication_code,internal) => {
    this.props.dispatch(authenticateUser(user,authentication_code,internal))
    this.props.dispatch(getUserReservations(user))
    if (this.redirectFrom) this.props.history.push(this.redirectFrom)
  }

  render() {
    const { authenticated, dispatch } = this.props
    const { email, sent } = this.state
    if (authenticated) return <Redirect to='/'/>
    return (
      <Row justify='center' align='middle' style={{height:'calc(100vh - 70px)'}}>
        <Col flex={1}>
          <div>
            <Title className='text-center' style={{color:'#0074bc'}} level={2}>
            { sent ?
              "Check your Inbox"
              :
              "Let's Get you Verified"
            }
            </Title>
          </div>
          <div>
            <Paragraph className='text-center'>
              { sent ?
                "Please enter the code we emailed you to verify."
                :
                "We'll send you a code to verify your E-Mail address."
              }
            </Paragraph>
          </div>
          <div style={{marginTop:'30px'}}>
            { sent ?
              <AuthForm dispatch={dispatch} reset={this.onReset} email={email} onAuthenticated={this.onAuthenticated}/>
              :
              <EmailForm onSubmit={this.onSubmitEmail}/>
            }
          </div>
        </Col>
      </Row>
    )
  }
}


export default connect(state => ({ authenticated:state.app.authenticated }))(Authenticate)
