
export const initialState = {
  loading:true,
  socket:null,
  connected:false,
  clientInfo:null,
  initialized:false,
  status:null,
  authenticated:null,
  reservations:null,
  userReservations:null,
  networkDown:false,
  modal:null,
  internal:false
}

export default (state = initialState, action) => {
  let { type, payload } = action
  // console.log(type,payload)
  switch (type) {

    case 'SOCKET_INITIALIZED':
      return { ...state, socket:payload }

    case 'SOCKET_CONNECTED':
      return { ...state, connected:payload }

    case 'SET_CLIENT_INFO':
      return { ...state, clientInfo:payload }

    case 'APP_LOADED':
      return { ...state, initialized:true, loading:false }

    case 'NETWORK_DOWN':
      return { ...state, status:payload, networkDown:true }

    case 'NETWORK_UP':
      return { ...state, status:null, networkDown:false }

    case 'LOADING':
      return { ...state, loading:payload }

    case 'SET_STATUS':
      return { ...state, status:payload }

    case 'SET_AUTHENTICATION':
      return { ...state, authenticated:payload.user, internal:payload.internal }

    case 'SET_RESERVATIONS':
      return { ...state, reservations:payload }

    case 'SET_USER_RESERVATIONS':
      return { ...state, userReservations:payload }

    case 'SET_MODAL':
      return { ...state, modal:payload }

    case 'CLOSE_MODAL':
      return { ...state, modal:null }

    default: return state
  }
}
