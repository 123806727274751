import React from 'react'
import { connect } from 'react-redux'
import { Modal, Button } from 'antd'

class Popup extends React.Component {
  state = {
    ModalText: 'Content of the modal',
    confirmLoading: false,
  }

  handleOk = () => {
    if (this.props.modal.onOk) this.props.modal.onOk()
    // this.props.dispatch({ type:'CLOSE_MODAL' })
  }

  handleCancel = () => {
    if (this.props.modal.onCancel) this.props.modal.onCancel()
    this.props.dispatch({ type:'CLOSE_MODAL' })
  }

  render() {
    const { modal } = this.props
    const { confirmLoading, ModalText } = this.state
    if (!modal) return null
    return (
      <div>
        <Modal
          className={modal.className||''}
          title={modal.title}
          visible={modal}
          onOk={this.handleOk}
          confirmLoading={modal.loading}
          onCancel={this.handleCancel}
        >
          <div style={{}}>
            { modal.content }
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(state => ({
  modal:state.app.modal
}))(Popup)
