import React from 'react'
import { Link } from 'react-router-dom'
import { Row } from 'antd'
import PageTitle from 'Components/PageTitle'
import Block from 'Components/Block'
import NextButton from 'Components/NextButton'
import booking from 'assets/booking.svg'
import server from 'assets/server.svg'
import program from 'assets/program.svg'

export default ({ hideIntro }) => (
  <>
    <PageTitle>Reserve a CueServer</PageTitle>
    <Row justify="center" gutter={[16,16]}>
      <Block
        title='RESERVE'
        text='Reserve a CueServer through the online reservation system. Reservations are free, the only requirement is a valid Email address.'
        img={booking}
      />
      <Block
        title='ADD'
        text="Add the CueServer to CueServer Studio (by clicking 'Add Remote'). After reserving a unit, you'll receive the CueServer details via Email."
        img={server}
      />
      <Block
        title='PROGRAM'
        text="Program your show with the remote CueServer as you would with a local unit. Once you're done, simply download the edited show."
        img={program}
      />
    </Row>
    <NextButton title='Get Started' path='/reserve/reserve-a-cueserver'/>
  </>
)
