import io from 'socket.io-client'
import { batch } from 'react-redux'
import { Cache, Session } from 'Utils'

export function init() {
  return function(dispatch) {
    dispatch(connectSocket(true))
    let user = Session('auth/state')
    let auth = Session('auth/auth_code')
    if (user && auth) {
      dispatch(reauthenticateUser(user, auth))
    } else {
      dispatch({ type:'APP_LOADED' })
    }
  }
}

export function get(path,data,callback=null,loading=true) {
  return function(dispatch,getState) {
    let socket = getState().app.socket
    let timeout = setTimeout(_=>dispatch(socketError('timeout')), 2500)
    let onReply = response => {
      clearTimeout(timeout)
      socket.off(path,onReply)
      if (loading) dispatch({ type:'LOADING', payload:false })
      if (callback) callback(response)
    }
    socket.on(path,onReply)
    socket.emit(path,data)
    if (loading) dispatch({ type:'LOADING', payload:true })
  }
}

export function post(path,data) {
  return function(dispatch,getState) {
    let socket = getState().app.socket
    let onReply = response => {
      socket.off(path,onReply)
    }
    socket.on(path,onReply)
    socket.emit(path,data)
  }
}

export function getReservationStatus() {
  return function(dispatch,getState) {
    let netDown = getState().app.networkDown
    dispatch(get('/reservations/status', null, resp => {
      let { available, reserved, offline } = resp
      if (offline) {
        if (!netDown) dispatch(networkDown())
        setTimeout(_=>dispatch(getReservationStatus()),5000)
      } else {
        dispatch({ type:'SET_RESERVATIONS', payload:{ available, reserved } })
        if (netDown) dispatch({ type:'NETWORK_UP' })
      }
    }, netDown ? false:true))
  }
}

export function getUserReservations(user,callback) {
  return function(dispatch,getState) {
    dispatch(get('/reservations/user', { email:user }, resp => {
      dispatch({ type:'SET_USER_RESERVATIONS', payload:resp })
    }))
  }
}

export function authenticateUser(user,authentication_code,internal) {
  return function(dispatch,getState) {
    Session('auth/state', user)
    Session('auth/auth_code', authentication_code)
    Session('auth/locked', '')
    Session('auth/attempts', '')
    Session('auth/status','')
    batch(() => {
      dispatch({ type:'SET_AUTHENTICATION', payload:{ user, internal } })
      dispatch({ type:'APP_LOADED' })
    })
  }
}

export function reauthenticateUser(user,auth) {
  return function(dispatch,getState) {
    let onReauthResponse = ({ user:email, authentication_code, internal }) => {
      if (authentication_code) {
        dispatch(authenticateUser(email,authentication_code,internal))
      } else {
        dispatch({ type:'APP_LOADED' })
      }
    }
    dispatch(get('/security/auth/reauth', { email:user, authentication_code:auth }, onReauthResponse, false))
  }
}

export function deAuthenticateUser() {
  return function(dispatch,getState) {
    Session('auth/state', '')
    dispatch({ type:'SET_AUTHENTICATION', payload:{ user:null, internal:false } })
  }
}

function networkDown() {
  return function(dispatch) {
    let title = 'Something is Technically Wrong'
    let message = 'The CueServer Farm network is down.<br/>We\'re working to fix it up and have things back to normal soon!'
    dispatch({ type:'NETWORK_DOWN', payload:{ type:'error', title, message } })
  }
}

function socketError(type) {
  return function(dispatch) {
    let message = ''
    switch(type) {
      case 'connect_error': message = "We're having trouble connecting to the server."; break;
      case 'disconnect': message = "We've lost contact with the server. Please stand by.."; break;
      case 'timeout': message = "The connection has timed out. Please stand by.."; break;
    }
    batch(() => {
      dispatch({ type:'SET_STATUS', payload:{ type:'error', message } })
      dispatch({ type:'SOCKET_CONNECTED', payload:false })
    })
  }
}

function socketConnected() {
  return function(dispatch) {
    batch(() => {
      dispatch({ type:'SET_STATUS', payload:null })
      dispatch({ type:'SOCKET_CONNECTED', payload:true })
    })
  }
}

function connectSocket(init=false) {
  return function(dispatch) {
    let socket = io('https://api.cueserver.com', {
      secure:true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax : 5000,
      reconnectionAttempts: 99999
    })
    socket.on('connect_error', _=>dispatch(socketError('connect_error')))
    socket.on('disconnect', _=>dispatch(socketError('disconnect')))
    socket.on('reconnect', _=>dispatch(socketConnected()))
    socket.on('connect', _=>dispatch(socketConnected()))
    socket.on('client_info', info => {
      if (info.internal) window.INTERNAL_USER = true
      dispatch({ type:'SET_CLIENT_INFO', payload:info })
    })
    socketIntercept(socket)
    dispatch({ type:'SOCKET_INITIALIZED', payload:socket })
  }
}

function socketIntercept(socket) {
  socket.onevent = packet => {
    if (packet.data) {
      let [ name, payload ] = packet.data
      if (window.DEBUG_MODE || window.INTERNAL_USER) {
        console.log('>>>', { name, payload })
      }
      socket._callbacks[`$${name}`][0](payload)
    }
  }
}
