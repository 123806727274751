import React from 'react'
import { Form, Input, Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'

const EmailForm = ({ onSubmit }) => {
  const [ , forceUpdate ] = React.useState()
  const [ form ] = Form.useForm()
  const isValid = email => /\S+@\S+\.\S+/.test(email)
  const isDisabled = () => !form.isFieldsTouched(true) || form.getFieldsError().filter(({ errors }) => errors.length).length
  React.useEffect(() => forceUpdate({}),[])
  return (
    <div style={{ display:'flex',justifyContent:'center',alignItems:'center'}}>
      <Form form={form} layout="inline" style={{width:'100%',maxWidth:'400px',justifyContent:'center'}} onFinish={e => onSubmit(e.email)}>
        <Form.Item name="email" style={{width:'65%'}} rules={[{ required:true, message:'Please input your email' }]}>
          <Input size='large' prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>
        <Form.Item shouldUpdate={true}>
          { _=> <Button size='large' type="primary" htmlType="submit" disabled={isDisabled()}>Submit</Button> }
        </Form.Item>
      </Form>
    </div>
  )
}


export default EmailForm
