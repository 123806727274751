import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Typography, Statistic, Button, Tooltip, Modal, notification, Table, Tag, Space } from 'antd'
import { WarningFilled } from '@ant-design/icons'
import { get, getUserReservations, getReservationStatus } from 'State/Actions'
import { Clipboard } from 'Utils'
import Reservations from './MyRentals/Reservations'
import empty from 'assets/empty.svg'
const { confirm } = Modal


class Manage extends React.Component {

  state = {
    reload:false
  }

  loadReservations = () => {
    this.props.dispatch(getReservationStatus())
    this.props.dispatch(getUserReservations(this.props.email))
  }

  reload = () => {
    this.props.dispatch({ type:'LOADING', payload:true })
    setTimeout(this.loadReservations,1000)
  }

  onConfirmCancel = () => {
    let { dispatch, email } = this.props
    dispatch(get('/reservations/cancel', { email }, resp => {
      if (resp.status == 'Reservation removed') {
        this.loadReservations()
      } else {
        console.log('error canceling reservation :/', resp)
      }
    }))
  }

  cancelReservation = () => {
    confirm({
      title: 'Are you sure you want to cancel?',
      icon: <WarningFilled />,
      okText:'Cancel Reservation',
      cancelText:"Back",
      okType:'primary',
      okButtonProps:{ danger:true },
      content: 'If you cancel this reservation the unit will no longer be accessible and any data on the CueServer will be destroyed.',
      onOk:this.onConfirmCancel
    })
  }

  copyHost = host => {
    notification.success({
      placement:'bottomLeft',
      message: 'Hostname Copied',
      duration:2,
      style:{ width:'auto' }
    })
    Clipboard(host)
  }

  componentDidMount() {
    this.loadReservations()
  }

  render() {
    let { reservations, email, internal, dispatch } = this.props
    if (!reservations) return null
    if (!reservations.length) return <NoReservations/>
    let list = reservations.map(res => ({
      name:res.cueserver,
      host:`${res.id}.csfarm.cueserver.com`,
      version:'v4.0.6',
      end:res.end
    }))
    list = [list[0]]
    return (
      <Row justify='center' align='top' style={{marginTop:'100px'}}>
        <Col flex={1}>
          <div>
            <Typography.Title className='text-center' style={{color:'#0074bc'}} level={3}>
              Your Reservation:
            </Typography.Title>
          </div>
          <div>
            <Typography.Paragraph className='text-center'>
              Below are the details of your current CueServer reservation.
            </Typography.Paragraph>
          </div>
          <div style={{padding:'50px'}}>
            <Reservations reservations={list} internal={internal} copy={this.copyHost} reload={this.reload} cancel={this.cancelReservation}/>
          </div>
        </Col>
      </Row>
    )
  }
}

export default connect(state => ({
  email:state.app.authenticated,
  reservations:state.app.userReservations,
  internal:state.app.internal
}))(Manage)

const Reservation = ({ data, copy, reload, cancel }) => {
  let { cueserver, id, end } = data
  end = new Date(end)
  return (
    <div style={{marginTop:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} className='text-center'>
      <div style={{background:'#fff', width:'80%',padding:'20px 30px',borderRadius:'5px'}} className='text-center'>
        <Row justify='center' align='middle' gutter={16}>
          <Col flex={1}>
            <Statistic title="CueServer" value={cueserver} />
          </Col>
          <Col flex={1}>
            <span onClick={()=>copy(`${id}.csfarm.cueserver.com`)} style={{cursor:'pointer'}}>
              <Tooltip title="Click to Copy" placement='bottom'>
                <Statistic title="Hostname" value={`${id}.csfarm.cueserver.com`} />
              </Tooltip>
            </span>
          </Col>
          <Col flex={1}>
            <Statistic.Countdown onFinish={reload} valueStyle={{color:((((end - new Date())/1000)/60) <= 10) ? '#f10505':''}} title="Time Remaining" value={end}/>
          </Col>
          <Col style={{textAlign:'right',marginTop:'20px'}} span={22}>
            <Button size='small' onClick={cancel} danger>Cancel Reservation</Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const NoReservations = () => (
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'calc(100vh - 70px)'}}>
    <div style={{position:'relative',display:'inline-block',width:'auto',textAlign:'center',background:'rgba(0, 0, 0, 0.05)',borderRadius:'4px',marginBottom:'20px',margin:'20px auto',overflowX:'hidden'}}>
      <div className='tumbleweed-container'>
        <div className='tumbleweed'/>
      </div>
      <div style={{padding:'40px 100px'}}>
        <img src='https://assets.interactive-online.com/global/empty.gif' style={{display:'block',margin:'0 auto',filter:'contrast(0.9)',maxWidth:'200px'}}/>

        <br/>
        <Typography.Title className='text-center' style={{color:'#0074bc', display:'block',marginTop:'50px'}} level={3}>There's Nothing Here</Typography.Title>
        You have no active reservations.
        </div>
    </div>
  </div>
)
