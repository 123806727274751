import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'

export default ({ title, path }) => (
  <Row gutter={[0,50]}>
    <Col className='text-center' span={24}>
      <Link to={path}>
        <Button style={{marginTop:'30px'}} type="primary">
          { title } <ArrowRightOutlined/>
        </Button>
      </Link>
    </Col>
  </Row>
)
