import React from 'react'
import { Row, Col, Typography, Button, Input } from 'antd'

class Form extends React.Component {

  state = {
    serial:null,
    loading:false,
    valid:false
  }

  isValidSerial = serial => serial && /[0-9]{6}/.test(serial)

  onSerialChange = e => {
    let val = e.target.value
    if (isNaN(val)) return
    if (this.props.error) this.props.clearError()
    this.setState({ serial:val, valid:this.isValidSerial(val) })
  }

  onSerialSubmit = () => {
    if (this.isValidSerial(this.state.serial)) {
      this.setState({ loading:true })
      this.props.onSubmit(this.state.serial)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
      this.setState({ serial:null, loading:false })
    }
  }

  render() {
    let { serial, loading, valid } = this.state
    let error = this.props.error||this.state.error||null

    return (
      <Row justify='center' align='top' style={{marginTop:'100px'}}>
        <Col flex={1}>
          <div>
            <Typography.Title className='text-center' style={{color:'#0074bc'}} level={3}>
              Lookup a CueServer
            </Typography.Title>
          </div>
          <div>
            <Typography.Paragraph className='text-center'>
              Please enter your CueServer Serial Number below:
            </Typography.Paragraph>
          </div>
          <div style={{marginTop:'30px',display:'flex',alignItems:'center',justifyContent:'center'}} className='text-center'>
            <Row justify='center' gutter={10}>
              <Col flex='3'>
                <Input onChange={this.onSerialChange} value={serial} style={{width:'175px'}} size='medium' placeholder="XXXXXX" />
              </Col>
              <Col flex='auto'>
                <Button size='medium' onClick={this.onSerialSubmit} loading={loading} disabled={valid ? false:true} type='primary'>Find</Button>
              </Col>
            </Row>
          </div>
          <div style={{ display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>
            <Typography.Paragraph type='danger' className={`text-center form-error${error ? ' visible':''}`}>{ error }</Typography.Paragraph>
          </div>
        </Col>
      </Row>
    )
  }
}

export default Form
