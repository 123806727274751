import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import AdminRoute from 'Components/AdminRoute'
import PrivateRoute from 'Components/PrivateRoute'
import HowItWorks from './HowItWorks'
import ReserveACueServer from './ReserveACueServer'
import Manage from './Manage'

export default () => {
  return (
  <Switch>
    <Route path='/reserve/how-it-works' component={HowItWorks}/>
    <PrivateRoute path='/reserve/reserve-a-cueserver' component={ReserveACueServer}/>
    <PrivateRoute path='/reserve/manage-reservation' component={Manage}/>
    <AdminRoute path='/reserve/status' component={Manage}/>
    <Route render={ props => <Redirect to='/reserve/how-it-works'/> } />
  </Switch>
)}
