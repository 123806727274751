import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { get, getReservationStatus, getUserReservations } from 'State/Actions'
import { Typography, Row, Col, TimePicker, Button, Select } from 'antd'
import Loading from 'Components/Loading'
import max from 'assets/max.svg'
import unavailable from 'assets/sold_out.svg'
const { Title, Paragraph } = Typography
const { Option } = Select

class ReserveACueServer extends React.Component {

  state = {
    time:null,
    reserved:false
  }

  loadReservations = () => {
    this.props.dispatch(getReservationStatus())
    this.props.dispatch(getUserReservations(this.props.email))
  }

  makeReservation = () => {
    const { email } = this.props
    this.props.dispatch(get('/reservations/add',{ email, end:this.state.time }, resp => {
      if (resp.status === 'Reservation added') {
        this.loadReservations()
        this.toManagePage()
      } else {
        this.loadReservations()
        console.log('Something went wrong :/', resp)
      }
    }))
  }

  onChange = amount => {
    let currentTime = new Date()
    currentTime.setHours(currentTime.getHours()+amount)
    console.log(currentTime.toLocaleString({ timeZone: 'America/New York' }))
    this.setState({ time:currentTime })
  }

  toManagePage = () => this.props.history.push('/reserve/manage-reservation')

  componentDidMount() {
    this.loadReservations()
  }

  render() {
    const { reservations, userReservations, loading } = this.props
    const { start, time } = this.state
    if (!reservations || !userReservations) return null
    if (userReservations.length >= 1) return <TooMany manage={this.toManagePage}/>
    if (reservations.available === 0) return <Unavailable/>
    let endTime = this.state.time ? this.state.time.toLocaleTimeString({ timeZone: 'America/New York' }):null
    endTime = endTime ? `${this.state.time.getDate() === new Date().getDate() ? 'Today':'Tomorrow'} at ${endTime.replace(/:\d{2}\s/,' ')}`:null
    return (
      <Row justify='center' align='top' style={{marginTop:'100px'}}>
        <Col flex={1}>
          <div>
            <Title className='text-center' style={{color:'#0074bc'}} level={3}>
              How long do you need the CueServer?
            </Title>
          </div>
          <div>
            <Paragraph className='text-center'>
              You can reserve up to 8 hours per reservation.<br/>Please select an allotment of time below.
            </Paragraph>
          </div>
          <div style={{marginTop:'30px'}} className='text-center'>
            <Select size='large' defaultValue="How Long?" style={{width:'200px'}} onChange={this.onChange}>
              { new Array(8).fill(x=>1).map((x,i) => <Option key={i} value={i+1}>{`${i+1} hour${i+1>1 ? 's':''}`}</Option>) }
            </Select>
            <Button onClick={this.makeReservation} style={{marginLeft:'10px',height:'38px'}} size='large' type="primary" htmlType="submit" disabled={time ? false:true}>Reserve</Button>
          </div>
          { this.state.time &&
            <div style={{textAlign:'center',marginTop:'20px',color:'#005b94',fontStyle:'italic',fontSize:'12px'}}>
              <span>Reservation will end {endTime}</span>
            </div>
          }
        </Col>
      </Row>
    )
  }
}

export default connect(state => ({
  email:state.app.authenticated,
  reservations:state.app.reservations,
  userReservations:state.app.userReservations
}))(ReserveACueServer)


const TooMany = (props) => (
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'calc(100vh - 70px)'}}>
    <div style={{display:'inline-block',width:'auto',textAlign:'center',background:'rgba(0, 0, 0, 0.05)',borderRadius:'4px',marginBottom:'20px',padding:'40px 100px',margin:'20px auto'}}>
      <img src={max} style={{display:'block',marginBottom:'25px',marginLeft:'calc(50% - 87.25px)',maxWidth:'175px'}}/>
      <br/>
      <Typography.Title className='text-center' style={{color:'#0074bc', display:'block'}} level={3}>You're Maxed Out</Typography.Title>
      You've already checked out the maximum number of units.<br/>
      You can manage your existing rentals on the <a onClick={props.manage}>My Rentals</a> page.
    </div>
  </div>
)

const Unavailable = (props) => (
  <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:'100%',height:'calc(100vh - 70px)'}}>
    <div style={{display:'inline-block',width:'auto',textAlign:'center',background:'rgba(0, 0, 0, 0.05)',borderRadius:'4px',marginBottom:'20px',padding:'40px 100px',margin:'20px auto'}}>
      <img src={unavailable} style={{display:'block',marginBottom:'25px',marginLeft:'calc(50% - 87.25px)',maxWidth:'175px'}}/>
      <br/>
      <Typography.Title className='text-center' style={{color:'#0074bc', display:'block'}} level={3}>We're at Full Capacity</Typography.Title>
      It looks like there aren't any CueServers available right now.
      <br/>
      Please check back later.
    </div>
  </div>
)
