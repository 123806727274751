import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Row, Col, Typography, Statistic, Button, Input, Table, Tag, Select, Divider } from 'antd'

class Display extends React.Component {

  reload = () => {
    this.clearView()
    this.props.reload()
  }

  clearView = () => this.setState({ manage:null })
  setApplyView = () => this.setState({ manage:'apply' })
  setTransferView = () => this.setState({ manage:'transfer' })

  render() {
    let { serial, info } = this.props

    return (
      <Row justify='center' align='top' style={{marginTop:'50px'}}>
        <Col flex={1}>
          <div>
            <Typography.Title className='text-center' style={{color:'#0074bc'}} level={3}>
              Serial Number:
            </Typography.Title>
          </div>
          <div>
            <Typography.Paragraph className='text-center' style={{fontSize:'16px',fontWeight:600,color:'#dc1e26'}}>
              { serial }
            </Typography.Paragraph>
          </div>
          <div style={{marginTop:'30px',display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'50px'}} className='text-center'>
            <div style={{background:'#fff', width:'80%',padding:'40px 30px',borderRadius:'5px'}} className='text-center'>
              <Row justify='center'>
                <Col flex='1'>
                  <Statistic title="Model" value={info.model} />
                </Col>
                <Col flex='1'>
                  <Statistic title="Universes Licensed" value={info.currentUniverses} />
                </Col>
                <Col flex='1'>
                  <Statistic title="Sale Date" value={info.saleDate||'Unknown'} />
                </Col>
                <Col flex='1'>
                  <Statistic title="Warranty Status" value={info.warrantyStatus||'Unknown'} />
                </Col>
              </Row>
              <Divider/>
              <Row style={{marginTop:'20px'}}>
                <Col flex='1'>
                  <Statistic title="License Code" value={info.licenseCode||'N/A'} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    )
  }
}



export default connect(state => ({}))(Display)
