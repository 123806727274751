import React from 'react'
import { Row, Col, Input, Button, Typography } from 'antd'
import { get } from 'State/Actions'
import Status from 'Components/Status'
import { Session } from 'Utils'

class AuthForm extends React.Component {
  state = {
    digits:{},
    invalid:false,
    loading:false,
    locked:Session('auth/locked'),
    attempts:Session('auth/attempts')||0
  }

  set = (num,val) => {
    if (val === ' ') return
    if (isNaN(val)) return
    this.setState({ digits:{ ...this.state.digits, [num]:val }, invalid:false })
    if (this.inputs[num+1] && val) this.inputs[num+1].focus()
  }

  clear = (d,e) => {
    if (e.key === 'Backspace' && d > 1) {
      if (d == 6 && this.state.digits[d]) {
        this.setState({ digits:{ ...this.state.digits, [d]:'' } })
      } else {
        if (this.state.digits[d]) {
          this.inputs[d].focus()
          this.setState({ digits:{ ...this.state.digits, [d]:'' } })
        } else {
          this.inputs[d-1].focus()
          this.setState({ digits:{ ...this.state.digits, [d-1]:'', [d]:'' } })
        }
      }
    } else if (e.key === 'Enter') {
      if (this.isValid) this.verify()
    } else if (!isNaN(e.key)) {
      this.setState({ digits:{ ...this.state.digits, [d]:'' } })
    }
    if (e.key === 'ArrowLeft' && d > 1) this.inputs[d-1].focus()
    if (e.key === 'ArrowRight' && d < 6) this.inputs[d+1].focus()
  }

  onInvalid = () => {
    let attempts = Number(this.state.attempts)+1, locked
    if (attempts > 5) {
      let now = new Date()
      now.setMinutes(now.getMinutes()+10)
      locked = now
      Session('auth/locked', locked)
    }
    Session('auth/attempts', attempts)
    this.setState({ invalid:true, digits:{}, attempts, locked })
    if (this.inputs[1]) this.inputs[1].focus()
    setTimeout(_=>this.setState({ invalid:false }), 5000)
    console.log('Invalid code!!')
  }

  verify = () => {
    let code = Object.keys(this.state.digits).map(x=>this.state.digits[x]).join('')
    this.props.dispatch(get('/security/auth/verify', { email:this.props.email, code }, this.onVerifyResponse))
  }

  onVerifyResponse = ({ user, authentication_code, internal }) => {
    if (authentication_code) {
      this.props.onAuthenticated(user,authentication_code,internal)
    } else {
      this.onInvalid()
    }
  }

  isValid = () => Array(6).fill(1).reduce((acc,d,i) => acc = acc ? this.state.digits[i+1]&&this.state.digits[i+1]!==undefined:acc,true)

  inputs = {}
  setRef = (num,ref) => this.inputs[num] = ref

  inputStyle = { padding:'6.5px 5px', textAlign:'center', color:'transparent', textShadow:'0 0 0 #2196f3' }

  onPaste = e => {
    let text = e.clipboardData.getData('Text')
    if (text.length === 6 && !isNaN(text)) {
      e.preventDefault()
      let code = {}
      text.split('').forEach((x,i) => code[i+1] = x)
      this.setState({ digits:code })
    }
  }

  componentDidMount() {
    if (this.inputs[1]) this.inputs[1].focus()
  }

  render() {
    const { digits, invalid, locked, loading } = this.state
    let lockTime = null
    if (locked) {
      lockTime = Math.round(((new Date(locked) - new Date())/1000)/60)
      if (lockTime < 0) {
        this.setState({ locked:false, attempts:0 })
        Session('auth/locked','')
        Session('auth/attempts', 0)
      }
    }
    return (
      <Status loading={loading} deny={locked ? { title:'Too Many Attempts', text:`You've tried too many times, try again in ${lockTime == 0 ? 1:lockTime} minute${lockTime > 1 ? 's':''}.` }:false}>
        <div style={{ display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Row gutter={[16,16]} style={{marginTop:'10px'}} justify='center'>
            <Col flex={'1'}>
              <Row gutter={[7]} justify='center' style={{width:'240px'}}>
                <Col onPaste={this.onPaste} flex='1'><Input ref={r=>this.setRef(1,r)} onKeyDown={e=>this.clear(1,e)} onChange={e=>this.set(1,e.target.value)} value={digits[1]} maxLength={1} style={this.inputStyle} size='large' name='digit-1' /></Col>
                <Col onPaste={this.onPaste} flex='1'><Input ref={r=>this.setRef(2,r)} onKeyDown={e=>this.clear(2,e)} onChange={e=>this.set(2,e.target.value)} value={digits[2]} maxLength={1} style={this.inputStyle} size='large' name='digit-2' /></Col>
                <Col onPaste={this.onPaste} flex='1'><Input ref={r=>this.setRef(3,r)} onKeyDown={e=>this.clear(3,e)} onChange={e=>this.set(3,e.target.value)} value={digits[3]} maxLength={1} style={this.inputStyle} size='large' name='digit-3' /></Col>
                <Col onPaste={this.onPaste} flex='1'><Input ref={r=>this.setRef(4,r)} onKeyDown={e=>this.clear(4,e)} onChange={e=>this.set(4,e.target.value)} value={digits[4]} maxLength={1} style={this.inputStyle} size='large' name='digit-4' /></Col>
                <Col onPaste={this.onPaste} flex='1'><Input ref={r=>this.setRef(5,r)} onKeyDown={e=>this.clear(5,e)} onChange={e=>this.set(5,e.target.value)} value={digits[5]} maxLength={1} style={this.inputStyle} size='large' name='digit-5' /></Col>
                <Col onPaste={this.onPaste} flex='1'><Input ref={r=>this.setRef(6,r)} onKeyDown={e=>this.clear(6,e)} onChange={e=>this.set(6,e.target.value)} value={digits[6]} maxLength={1} style={this.inputStyle} size='large' name='digit-6' /></Col>
              </Row>
            </Col>
            <Col flex={'auto'}>
              <Button { ...this.isValid() ? {}:{disabled:true} } onClick={this.isValid() ? this.verify:null} size='large' type="primary">Submit</Button>
            </Col>
          </Row>
        </div>
        <div style={{ display:'flex',justifyContent:'center',alignItems:'center'}}>
          <Typography.Paragraph type='danger' className={`text-center form-error${invalid ? ' visible':''}`}>The code you entered was incorrect</Typography.Paragraph>
        </div>
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>
          <div style={{color:'#b1b1b1',fontSize:'11px'}}>
            Can't find your code? <a onClick={this.props.reset}>Try Re-Sending it</a>.
          </div>
        </div>
      </Status>
    )
  }
}

export default AuthForm
