import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

const PrivateRoute = ({ authenticated, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/authenticate', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default connect(state=>({ authenticated:state.app.authenticated }))(PrivateRoute)
