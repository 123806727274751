import React from 'react'
import { Table, Tag, Space, Statistic, Tooltip } from 'antd'
import { CloseCircleOutlined, SelectOutlined } from '@ant-design/icons'

export default ({ reservations, copy, reload, cancel, internal }) => {
  // let { id, cueserver:name, end } = data
  // end = new Date(end)
  const openInStudio = () => {
    let link = document.createElement('a')
    link.href = 'cs-studio://idk'
    link.click()
    link.remove()
  }


  const columns = [
    { title:'CueServer', dataIndex: 'name', key: 'name', render: name => ( <b>{ name }</b> ) },
    { title:'Address', dataIndex: 'host', key: 'host', render: host => (
        <span onClick={()=>copy(host)} style={{cursor:'pointer'}}>
          <Tooltip title="Click to Copy" placement='bottom'>
            <a>{host}</a>
          </Tooltip>
        </span>
      )
    },
    { title:'Version', dataIndex: 'version', key: 'version' },
    { title:'Time Remaining', dataIndex: 'end', key: 'end', align:'center', render: end => (
        <Statistic.Countdown
          onFinish={reload}
          valueStyle={{ color:((((end - new Date())/1000)/60) <= 10) ? '#f10505':'', fontSize:'20px'}}
          value={new Date(end)}
        />
      )
    },
    { title:'Actions', dataIndex: 'actions', key: 'actions', render: actions => actions.map(action =>
        <a key={action.name} onClick={action.action}>
          <Tag icon={action.icon} color={action.color}>{ action.name }</Tag>
        </a>
      )
    }
  ]
  reservations.forEach((x,i) => {
    reservations[i].key = i
    reservations[i].actions = internal ? [
      { name:'Cancel', color:'red', action:cancel, icon:<CloseCircleOutlined/> },
      { name:'Open Studio', color:'purple', action:openInStudio, icon:<SelectOutlined/> },
    ]:[
      { name:'Cancel', color:'red', action:cancel, icon:<CloseCircleOutlined/> },
    ]
  })

  return (
    <Table columns={columns} dataSource={reservations} />
  )
}
