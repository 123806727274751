import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, Typography, Statistic, Button, Input, Table, Tag, Select, Divider } from 'antd'
import { get } from 'State/Actions'

class Apply extends React.Component {

  state = {
    serial:null,
    count:'default',
    loading:false,
    valid:false
  }

  isValidSerial = s => s.length === 6 && !isNaN(s)
  isValidCount = c => !isNaN(c)

  setSerial = val => {
    let serial = isNaN(val) ? this.state.serial:val
    let valid = this.isValidSerial(serial) && this.isValidCount(this.state.count)
    this.setState({ serial, valid })
  }

  setCount = val => {
    let count = this.isValidCount(val) ? val:this.state.count
    let valid = this.isValidCount(count) && this.isValidSerial(this.state.serial)
    this.setState({ count, valid })
  }

  applyUniverses = () => {
    let { user, code } = this.props
    this.setState({ loading:true })
    let params = { user, code, count:this.state.count, target:this.state.serial }
    this.props.dispatch(get('/utils/records/licenses/transaction', params, resp => {
      this.setState({ loading:false })
      if (resp.error) {
        this.props.dispatch({
          type:'SET_MODAL',
          payload:{
            title:'Unable to Apply Universes',
            content:resp.error,
            onOk:_=>this.props.dispatch({ type:'CLOSE_MODAL' }),
          }
        })
      } else {
        this.props.dispatch({
          type:'SET_MODAL',
          payload:{
            title:'Success!',
            content:(
              <Typography.Paragraph className='text-center'>
                You've successfuly applied <b>{this.state.count}</b> universes to serial <b>{this.state.serial}</b>.
                <br/>
                <br/>
                <b>Your new License Code is:</b> {resp.license_code}
              </Typography.Paragraph>
            ),
            onOk:_=>{
              this.props.dispatch({ type:'CLOSE_MODAL' })
              this.props.reload()
            },
          }
        })
      }
      console.log(resp)
    },false))
  }

  render() {
    const { code, info, clearView } = this.props
    const { serial, count, loading, valid } = this.state
    const universeList = new Array(info.remainingUniverses).fill(1)

    return(
      <div style={{marginTop:'30px',textAlign:'left'}}>
        <Typography.Title style={{fontSize:'15px',textAlign:'center',marginBottom:'25px'}}>Use the form below to apply licenses to a CueServer</Typography.Title>
        <Divider/>
        <Row gutter={[16,16]}>
          <Col flex='125px'>
            <Typography.Text>License Code:</Typography.Text>
          </Col>
          <Col flex='auto'>
            <Input style={{maxWidth:'225px'}} size='small' value={code} readOnly={true} />
          </Col>
        </Row>
        <Row gutter={[16,16]}>
          <Col flex='125px'>
            <Typography.Text>CueServer:</Typography.Text>
          </Col>
          <Col flex='auto'>
            <Input size='small' style={{maxWidth:'150px'}} value={serial} placeholder='serial number' onChange={e=>this.setSerial(e.target.value)} />
          </Col>
        </Row>
        <Row gutter={[16,16]}>
          <Col flex='125px'>
            <Typography.Text>Universes:</Typography.Text>
          </Col>
          <Col flex='auto'>
            <Select size='small' style={{ width: 150 }} value={count} onChange={e=>this.setCount(e)}>
              <Select.Option value="default">Choose..</Select.Option>
            { universeList.map((x,i)=>(

              <Select.Option key={i} value={i+1}>{i+1}</Select.Option>
            ))}
            </Select>
          </Col>
        </Row>
        <Divider/>
        <Row style={{marginTop:'20px'}}>
          <Col style={{textAlign:'left'}} flex='5'>
            <Button onClick={clearView} type='default'>Go Back</Button>
          </Col>
          <Col style={{textAlign:'right'}} flex='5'>
            <Button onClick={this.applyUniverses} disabled={valid ? false:true} loading={loading ? true:false} type='primary'>Apply</Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect(state => ({
  user:state.app.authenticated
}))(Apply)
