

export const Clipboard = str => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export const Session = (key, val=null) => {
  if (val===null) {
    let stored = window.localStorage.getItem(key)
    stored = stored=='true' ? true:stored
    stored = stored=='false' ? false:stored
    try { return JSON.parse(stored) } catch(err) { return stored }
  } else {
    val = typeof val == 'object' ? JSON.stringify(val):val
    window.localStorage.setItem(key, val)
  }
}

// export const Session = (key,val=null) => {
//   let session = window.sessionStorage.getItem('session-state')||{}
//   if (typeof session === 'string') session = JSON.parse(session)
//   if (val===null) {
//     return session[key]||null
//   } else {
//     session[key] = val
//     window.sessionStorage.setItem('session-state',JSON.stringify(session))
//   }
// }
