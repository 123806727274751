import React from 'react'
import { Switch, Route, Redirect, Link } from 'react-router-dom'
import { Layout, Menu, Breadcrumb } from 'antd'
import { connect } from 'react-redux'
import Nav from 'Components/Nav'
import Status from 'Components/Status'
import Loading from 'Components/Loading'
import Popup from 'Components/Popup'
import Home from 'Containers/Home'
import License from 'Containers/Licensing'
import Reserve from 'Containers/Reserve'
import Authenticate from 'Containers/Authenticate'
import './App.css'

const { Content, Footer } = Layout

export default connect(state => ({ initialized:state.app.initialized }))(({ initialized }) => (
  <div className="App">
    <Layout className="layout">
      <Nav/>
      <Layout className="layout">
        <Content style={{ padding: '0 50px' }}>
          <div className="site-layout-content">
            <Loading/>
            { initialized &&
              <Status>
                <Switch>
                  <Route path="/reserve" component={Reserve}/>
                  <Route path="/authenticate" component={Authenticate}/>
                  <Route render={props => <Redirect to='/reserve/how-it-works'/>} />
                </Switch>
              </Status>
            }
          </div>
        </Content>
        <Popup/>
        <Footer style={{textAlign: 'center'}}>© 1996-{new Date().getFullYear()} Interactive Technologies, Inc. All rights reserved.</Footer>
      </Layout>
    </Layout>
  </div>
))
