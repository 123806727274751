import React from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Layout, Menu, Button, Tooltip } from 'antd'
import { HddOutlined, BarcodeOutlined, WarningOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { deAuthenticateUser } from 'State/Actions'

const SubMenuTitle = ({ title, icon }) => {
  let Icon = icon||null
  return (
    <span>
      { Icon && <Icon/> }
      <span>{title}</span>
    </span>
  )
}

const Group = ({ title, children }) => (
  <Menu.ItemGroup key={title} title={title}>
    { children }
  </Menu.ItemGroup>
)

const Nav = ({ loggedIn, networkDown, internal, dispatch }) => {
  let location = useLocation(), history = useHistory()
  let selected = [], open = []
  let keys = ['/reserve/how-it-works','/reserve/reserve-a-cueserver','/reserve/manage-reservation','/reserve/status']
  keys.forEach(key => { if (location.pathname.includes(key)) selected.push(key) })
  open = location.pathname.includes('reserve') ? ['reserve']:location.pathname.includes('licensing') ? ['licensing']:[]
  return (
    <Layout.Sider
      theme="light"
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={broken => {
        // console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        // console.log(collapsed, type)
      }}
    >
      <div className="logo" />
      <Menu theme='light' mode='inline' selectedKeys={selected} openKeys={open}>
        <Menu.SubMenu onTitleClick={_=>history.push('/reserve/how-it-works')} key='reserve' title={<SubMenuTitle title='CueServer Farm' icon={HddOutlined}/>}>
          <Menu.Item key='/reserve/reserve-a-cueserver'>
            <Link to='/reserve/reserve-a-cueserver' style={{position:'relative'}}>
              Rent a CueServer
              { networkDown &&
                <Tooltip title='The CueServer Farm network is offline' placement='right' overlayClassName='warn'>
                  <figure>
                    <WarningOutlined className='warn'/>
                  </figure>
                </Tooltip>
              }
            </Link>
          </Menu.Item>
          { loggedIn &&
            <Menu.Item key='/reserve/manage-reservation'>
              <Link to='/reserve/manage-reservation' style={{position:'relative'}}>
                My Rentals
                { networkDown &&
                  <Tooltip title='The CueServer Farm network is offline' placement='right' overlayClassName='warn'>
                    <figure>
                      <WarningOutlined className='warn'/>
                    </figure>
                  </Tooltip>
                }
              </Link>
            </Menu.Item>
          }
          { (loggedIn && internal) &&
            <Menu.Item key='/reserve/status'>
              <Link to='/reserve/status' style={{position:'relative'}}>
                Farm Status
                <Tooltip title='Visible to Admin/Staff only' placement='right' overlayClassName='danger'>
                  <figure>
                    <EyeInvisibleOutlined className='danger'/>
                  </figure>
                </Tooltip>
              </Link>
            </Menu.Item>
          }
        </Menu.SubMenu>
      </Menu>
      { loggedIn &&
        <div style={{position:'fixed',bottom:'0px',width:'100%'}}>
          <Button onClick={_=>dispatch(deAuthenticateUser())} style={{marginLeft:'10px',padding:0}} type='link'>
            Log Out
          </Button>
          { internal &&
            <div className='internal-notice'>
              <Tooltip title='You are viewing as Admin/Staff' placement='top' overlayClassName='danger'>
                <EyeInvisibleOutlined className='it-blue' style={{marginRight:'2px',lineHeight:'14px'}}/> Admin/Internal User
              </Tooltip>
            </div>
          }
          <div style={{paddingLeft:'11px',paddingBottom:'5px',width:'100%',fontSize:'11px',color:'#c7c5c5'}}>
            { loggedIn }
          </div>
        </div>
      }
    </Layout.Sider>
  )
}


export default connect(state => ({
  loggedIn:state.app.authenticated,
  networkDown:state.app.networkDown,
  internal:state.app.internal
}))(Nav)
