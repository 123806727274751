import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import appReducer from './Reducer'

const createRootReducer = () => combineReducers({
  app:appReducer,
})


export default () => {
  const store = createStore(
    createRootReducer(),
    applyMiddleware(thunk)
  )
  return store
}
